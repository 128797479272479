import { api, createPromise } from '../../common/domain';
import type { CreateReviewRequest } from './requests/CreateReviewRequest';
import type { ReviewerRelationTypeResponse } from './responses/ReviewerRelationTypeResponse';
import type { ReviewForEditResponse } from './responses/ReviewForEditResponse';
import type { UpdateReviewRequest } from './requests/UpdateReviewRequest';
import type { ReportReviewRequest } from './requests/ReportReviewRequest';
import type { ReviewReportReasonsResponse } from './responses/ReviewReportReasonsResponse';
import type { PublishReviewRequest } from './requests/PublishReviewRequest';

function getReviewerRelationTypes() {
    return (api().get<ReviewerRelationTypeResponse[]>(`review/reviewer-relation-types`));
}

function getReviewByEditToken(reviewId: number, editToken: string) {
    return (api().get<ReviewForEditResponse>(`review/${reviewId}/edit/${editToken}`));
}

function createReview(createReviewRequest: CreateReviewRequest) {
    return createPromise(
        api().post('/review', createReviewRequest),
    );
}

function updateReview(updateReviewRequest: UpdateReviewRequest) {
    return api().put('/review', updateReviewRequest);
}

function reportReview(reportReviewRequest: ReportReviewRequest) {
    return api().post('review-report', reportReviewRequest);
}

function getReviewReportReasons() {
    return api().get<ReviewReportReasonsResponse[]>('review-report/review-report-reasons');
}

function publishReview(request: PublishReviewRequest) {
    return api().put(`/review/${request.reviewId}/publish`, request);
}

export default {
    getReviewByEditToken,
    getReviewerRelationTypes,
    createReview,
    updateReview,
    reportReview,
    getReviewReportReasons,
    publishReview,
};
