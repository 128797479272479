export function goTo(call: (...args: any[]) => string, args: any[], newWindow?: boolean) {
    const url = get(call, args);

    if (newWindow) {
        window.open(url);
        return;
    }
    window.location.href = url;
}

export function get(call: (...args: any[]) => string, args: any[]) {
    return call(...args);
}
