export class PersistentStorageUseCase {
    /**
     * Gets a value by key from localstorage
     * @param storageKey
     */
    get(storageKey: string) {
        const value = localStorage.getItem(storageKey);
        if (value)
            return JSON.parse(value);

        return null;
    }

    /**
     * Stores a value by key to localstorage
     * @param storageKey
     * @param value
     */
    set(storageKey: string, value: any) {
        localStorage.setItem(storageKey, JSON.stringify(value));
    }
}
