import {
    ComparisonPloc,

    CreateReviewPloc,
    CreateReviewUseCase,

    InstitutionSitesUseCase,

    NotificationPloc,

    SearchPloc,
    SectorsUseCase,
} from '../../features';

import { PersistentStorageUseCase } from '../domain/usecases';

let comparisonPloc: ComparisonPloc;
let notificationPloc: NotificationPloc;

function provideComparisonPloc() {
    if (!comparisonPloc)
        comparisonPloc = new ComparisonPloc(new PersistentStorageUseCase());

    return comparisonPloc;
}

function provideNotificationPloc() {
    if (!notificationPloc)
        notificationPloc = new NotificationPloc();

    return notificationPloc;
}

function provideCreateReviewPloc() {
    return new CreateReviewPloc(new CreateReviewUseCase());
}

function provideSearchPloc() {
    return new SearchPloc(
        new InstitutionSitesUseCase(),
        new SectorsUseCase(),
    );
}

export const dependenciesLocator = {
    provideComparisonPloc,
    provideCreateReviewPloc,
    provideNotificationPloc,
    provideSearchPloc,
};
