import { FixedSteps } from 'public/src/components/create-review/types/FixedSteps';
import type { CreateReview, CreateReviewUseCase, InstitutionSite, Question } from '../domain';

import { Ploc } from '../../../common';

import type { ReviewForEditResponse } from '../../../api/review/responses/ReviewForEditResponse';
import { CreateReviewState } from './CreateReviewState';

export class CreateReviewPloc extends Ploc<CreateReview> {
    constructor(private createReviewUseCase: CreateReviewUseCase) {
        super(CreateReviewState);
    }

    setReviewStateForUpdate(reviewForEdit: ReviewForEditResponse, editToken: string) {
        this.changeState({
            ...this.state,
            updateReviewId: reviewForEdit.id,
            editToken,
            currentFixedStep: FixedSteps.Questions,
            isInstitutionSiteConfirmed: true,
            isReviewerRelationTypeConfirmed: true,
            areGuidelinesAccepted: true,
            currentActiveQuestion: 1,
            generalReview: reviewForEdit.experienceText,
            personalInfo: reviewForEdit.personalInfo,
            institutionSite: reviewForEdit.institutionSite,
            questions: reviewForEdit.answersPerCategories.flatMap(apc =>
                apc.answers.map(answer => ({
                    ...answer,
                    categoryCode: apc.category.code,
                    categoryShortName: apc.category.shortName,
                    categoryName: apc.category.name,
                    categoryExtraClarificationText: apc.category.extraClarificationText,
                    userScore: answer.score,
                    userRemark: answer.remark,
                    isSkipped: answer.score === null,
                }))),
        });
    }

    async setInstitutionSiteFromSuggestion(orgId: number) {
        const response = await this.createReviewUseCase.getDataForReview(orgId);

        this.reset();

        this.changeState({
            ...this.state,
            institutionSite: response as InstitutionSite,
            questions: response.institutionSiteReviewQuestionsPerCategories.flatMap(cq =>
                cq.questions.map(question => ({
                    ...question,
                    categoryCode: cq.category.code,
                    categoryShortName: cq.category.shortName,
                    categoryName: cq.category.name,
                    categoryExtraClarificationText: cq.category.extraClarificationText,
                    userScore: null,
                    userRemark: null,
                    isSkipped: false,
                }))),
        });
    }

    canOverallReviewScoreBeShown(): boolean {
        return this.state.institutionSite!.overallScore != null && this.state.institutionSite!.reviewCount >= 5;
    }

    reset() {
        this.changeState({
            ...this.state,
            areGuidelinesAccepted: false,
            areQuestionsCompleted: false,
            currentActiveQuestion: 1,
            currentFixedStep: 1,
            generalReview: '',
            isInstitutionSiteConfirmed: false,
            isReviewerRelationTypeConfirmed: false,
            isReviewSubmitted: false,
            institutionSite: null,
            personalInfo: {
                firstName: '',
                lastName: '',
                mailAddress: '',
                phoneNumber: '',
            },
            questions: [],
            reviewerRelationType: '',
            customReviewerRelationType: '',
            isUserConsent: false,
            isUserDeclarationOfHonour: false,
        });
    }

    acceptGuidelines() {
        this.changeState({
            ...this.state,
            areGuidelinesAccepted: true,
        });
    }

    goToPreviousQuestion() {
        this.resetScoreIfQuestionSkipped();

        if (this.state.currentActiveQuestion > 1)
            this.setCurrentActiveQuestion(this.state.currentActiveQuestion - 1);
        else
            this.goToPreviousFixedStep();
    }

    goToNextQuestion() {
        this.resetScoreIfQuestionSkipped();

        if (this.state.currentActiveQuestion < this.state.questions.length)
            this.setCurrentActiveQuestion(this.state.currentActiveQuestion + 1);

        else
            this.goToNextFixedStep();
    }

    private resetScoreIfQuestionSkipped() {
        if (this.state.questions[this.state.currentActiveQuestion - 1].isSkipped)
            this.state.questions[this.state.currentActiveQuestion - 1].userScore = null;
    }

    private setCurrentActiveQuestion(index: number) {
        this.changeState({
            ...this.state,
            currentActiveQuestion: index,
        });
    }

    getIsQuestionSkippable() {
        const count = this.state.questions.reduce((accumulator, question) => {
            return accumulator + (question.isSkipped ? 1 : 0);
        }, 0);

        return count < this.state.amountOfSkippableQuestions;
    }

    async submitReview() {
        this.getIsUpdate()
            ? await this.createReviewUseCase.updateReview(this.state)
            : await this.createReviewUseCase.createReview(this.state);

        this.changeState({
            ...this.state,
            isReviewSubmitted: true,
        });
    }

    getTotalSteps() {
        return this.state.questions.length > 0
            ? this.state.questions.length + 5
            : 0;
    }

    goToPreviousFixedStep() {
        if (this.state.currentFixedStep > 1) {
            this.changeState({
                ...this.state,
                currentFixedStep: this.state.currentFixedStep - 1,
            });
        }
    }

    goToNextFixedStep() {
        if (this.state.currentFixedStep <= FixedSteps.PersonalInformation) {
            this.changeState({
                ...this.state,
                currentFixedStep: this.state.currentFixedStep + 1,
            });
        }
    }

    getAverageUserScore() {
        const validScores: number[] = this.state.questions
            .filter(this.isQuestionScoreDefined)
            .map(question => question.userScore as number);

        if (validScores.length === 0)
            return 0;

        const sum = validScores.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        return Math.round(sum / validScores.length * 10) / 10;
    }

    getIsUpdate() {
        return !!this.state.editToken && !!this.state.updateReviewId;
    }

    isValidEmail() {
        const emailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return this.state.personalInfo.mailAddress.trim() !== '' && emailFormat.test(this.state.personalInfo.mailAddress);
    }

    userAcceptConsent() {
        return this.state.isUserConsent;
    }

    userAcceptDeclarationOfHonour() {
        return this.state.isUserDeclarationOfHonour;
    }

    userConfirmsUseOfPersonalInfo() {
        return this.state.isUserConfirmOfPersonalInfo;
    }

    isValidPhone() {
        const phoneNumber = this.state.personalInfo.phoneNumber;

        const phoneNumberPattern = /^\+?\d{1,4}?[-.\s]?\(?\d{1,4}?\)?[-.\s]?\d{1,9}$/;

        if (phoneNumber.trim() === '')
            return true;

        if (!phoneNumberPattern.test(phoneNumber))
            return false;

        return true;
    }

    private isQuestionScoreDefined(question: Question): question is Question & { userScore: number } {
        return question.userScore !== null && question.userScore !== undefined;
    }
}
