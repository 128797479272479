<script setup lang="ts">
import Overlay from './Overlay.vue';
import Window from './Window.vue';
import Button from './Button.vue';
import snippets from '@/utils/snippets';

const props = defineProps({
    title: {
        type: String,
    },
    text: {
        type: String,
    },
    confirmButtonText: {
        type: String,
        default: snippets.get('confirm'),
    },
});

const emits = defineEmits(['close']);

function close() {
    emits('close');
}
</script>

<template>
    <Overlay>
        <Window :title="props.title" class="c-window--compact vp9:w-4/12" @action="close">
            {{ props.text }}
            <template #footer>
                <div class="flex gap-4">
                    <Button
                        :text="props.confirmButtonText"
                        class="c-button--secondary c-button--small typo-small"
                        type="button"
                        @click="close"
                    />
                </div>
            </template>
        </Window>
    </Overlay>
</template>
