import type { CreateReview } from '../domain';

export const CreateReviewState: CreateReview = {
    amountOfSkippableQuestions: 3,
    areGuidelinesAccepted: false,
    areQuestionsCompleted: false,
    currentActiveQuestion: 1,
    currentFixedStep: 1,
    generalReview: '',
    isInstitutionSiteConfirmed: false,
    isReviewerRelationTypeConfirmed: false,
    isReviewSubmitted: false,
    institutionSite: null,
    personalInfo: {
        firstName: '',
        lastName: '',
        mailAddress: '',
        phoneNumber: '',
    },
    questions: [],
    reviewerRelationType: '',
    customReviewerRelationType: '',
    updateReviewId: null,
    editToken: null,
    isUserConsent: false,
    isUserDeclarationOfHonour: false,
    isUserConfirmOfPersonalInfo: false,
    reCaptchaToken: '',
};
