import { institutionSiteApi } from '../../../../api';
import type { Sector } from '../../../../api/institutionSite/responses/Sector';
import type { BaseResponse } from '../../../../common/domain';
import type { SectorFilter } from '../entities/SectorFilter';

export class SectorsUseCase {
    async getFilterNodes() {
        const response: BaseResponse<Sector[]> = await institutionSiteApi.getSectors();
        const result = response.Result!;

        const mapper = (type: any): SectorFilter => {
            return {
                key: type.code,
                label: type.name,
                children: type.institutionTypes ? type.institutionTypes.map(mapper) : [],
            };
        };

        return result.map(mapper);
    }
}
