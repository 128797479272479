import type { SectorFilter } from '../../domain/entities/SectorFilter';
import type { SectorFilterState } from '../../domain/entities/SectorFilterState';

export function mapSectorParamsToState(sectorFilters: SectorFilter[], params: string[]): SectorFilterState {
    let result: SectorFilterState = {};

    sectorFilters.forEach((filter: SectorFilter) => {
        let checked: boolean = params.includes(filter.key);
        let partialChecked: boolean = false;

        if (filter.children.length) {
            const childResult = mapSectorParamsToState(filter.children, params);
            const checkedChildCount: number = Object.keys(childResult).map((key: string) => {
                return childResult[key].checked;
            }).filter((checked: boolean) => checked).length;

            checked = filter.children.length === checkedChildCount;
            partialChecked = (checkedChildCount > 0 && filter.children.length !== checkedChildCount);

            result = { ...result, ...childResult };
        }

        result[filter.key] = { checked, partialChecked };
    });

    return result;
}
