import type { InjectionKey } from 'vue';

import type {
    ComparisonPloc,
    CreateReviewPloc,
    NotificationPloc,
    SearchPloc,
} from '@core';

export const COMPARISON_PLOC = Symbol() as InjectionKey<ComparisonPloc>;
export const CREATE_REVIEW_PLOC = Symbol() as InjectionKey<CreateReviewPloc>;
export const NOTIFICATION_PLOC = Symbol() as InjectionKey<NotificationPloc>;
export const SEARCH_PLOC = Symbol() as InjectionKey<SearchPloc>;
