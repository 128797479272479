import { api, createPromise } from '../../common/domain';
import type { PagedResponse } from '../../common/domain';
import type { InstitutionSite } from '../../features/search/domain/entities/InstitutionSite';

import type { GetInstitutionSitesListRequest } from './requests/GetInstitutionSiteListRequest';
import type { InstitutionSiteDetailForCreateReview } from './responses/InstitutionSiteDetailForCreateReview';
import type { Sector } from './responses/Sector';
import type { InstitutionSiteSuggestion } from './responses/InstitutionSiteSuggestion';
import type { InstitutionSiteCompareResponse } from './responses/InstitutionSiteCompareResponse';
import type { InstitutionSiteDetailResponse } from './responses/InstitutionSiteDetailResponse';
import type { GetInstitutionSiteReviewsRequest } from './requests/GetInstitutionSiteReviewsRequest';
import type { InstitutionSiteDetailReviewsResponse } from './responses/InstitutionSiteDetailReviewsResponse';

function get(params: {
    page?: number
    pageSize?: number
    searchString?: string
    types?: string[]
    provinces?: string[]
    customCityString?: string
    minScore?: number
    sortProperty?: string
}) {
    const getParams = {
        ...params.page && { Page: params.page.toString() },
        ...params.pageSize && { PageSize: params.pageSize.toString() },
        ...params.searchString && { 'FilterCollection.SearchParameter': params.searchString },
        ...params.types && { 'FilterCollection.Types': params.types },
        ...params.provinces && { 'FilterCollection.Location': params.provinces },
        ...params.customCityString && { 'FilterCollection.SearchCityParameter': params.customCityString },
        ...params.minScore && { 'FilterCollection.MinScore': params.minScore },
        ...params.sortProperty && { SortProperty: params.sortProperty },
    };

    return createPromise<PagedResponse<InstitutionSite[]>>(api().get(`/institutionSite/public`, {
        params: getParams,
    }));
}

function getSectors() {
    return createPromise<Sector[]>(
        api().get(`/institutionSite/sector`),
    );
}

function getDataForReview(institutionSiteId: number) {
    return createPromise<InstitutionSiteDetailForCreateReview>(
        api()
            .get(`/institutionSite/data-for-review/${institutionSiteId}`),
    );
}

function getSuggestionList(request: GetInstitutionSitesListRequest) {
    const params = new URLSearchParams();

    if (request.name)
        params.append('name', request.name);

    if (request.institutionType)
        params.append('institutionType', request.institutionType);

    if (request.excludeUnapprovedInstitutionSites)
        params.append('excludeUnapprovedInstitutionSites', request.excludeUnapprovedInstitutionSites.toString());

    return api().get<InstitutionSiteSuggestion[]>(`/institutionSite/public/list`, {
        params,
    });
}

function getInstitutionSitesForComparison(institutionSiteIds: Array<string>) {
    return api().get<InstitutionSiteCompareResponse>(`/institutionSite/compare`, {
        params: { institutionSiteIds },
    });
}

function getInstitutionSiteDetailById(institutionSiteId: number) {
    return api().get<InstitutionSiteDetailResponse>(`/institutionSite/${institutionSiteId}`);
}

function getInstitutionSiteDetailPagedReviews(request: GetInstitutionSiteReviewsRequest) {
    const params = new URLSearchParams();
    params.append('InstitutionSiteId', request.institutionSiteId.toString());
    params.append('Page', request.page.toString());
    params.append('PageSize', request.pageSize.toString());
    if (request.sortProperty)
        params.append('SortProperty', request.sortProperty);

    return api().get<PagedResponse<InstitutionSiteDetailReviewsResponse[]>>(`/institutionSite/${request.institutionSiteId}/reviews`, {
        params,
    });
}

export default {
    get,
    getSectors,
    getDataForReview,
    getSuggestionList,
    getInstitutionSitesForComparison,
    getInstitutionSiteDetailById,
    getInstitutionSiteDetailPagedReviews,
};
