import type { DataError } from './DataError';

export function handleApiError(error: any) {
    let dataError: DataError;

    switch (error.StatusCode) {
        case 400: {
            dataError = {
                kind: 'BadRequest',
                statusCode: error.StatusCode,
                message: errorMessagesToString(error.Errors),
                error: error.Errors,
            };
            break;
        }
        case 401: {
            dataError = {
                kind: 'Unauthorized',
                statusCode: error.StatusCode,
                message: errorMessagesToString(error.Errors),
                error: error.Errors,
            };
            break;
        }
        case 403: {
            dataError = {
                kind: 'Forbidden',
                statusCode: error.StatusCode,
                message: errorMessagesToString(error.Errors),
                error: error.Errors,
            };
            break;
        }
        case 404: {
            dataError = {
                kind: 'NotFound',
                statusCode: error.StatusCode,
                message: errorMessagesToString(error.Errors),
                error: error.Errors,
            };
            break;
        }
        default: {
            dataError = {
                kind: 'UnexpectedError',
                statusCode: 501,
                message: 'Request failed with status code 501',
                error: error.Errors,
            };
            break;
        }
    }

    return dataError;
}

function errorMessagesToString(errors: any): string {
    if (typeof errors === 'object') {
        // this code is used to map the FluentValidation errors into a combined string
        let returnString = '';
        Object.entries(errors).forEach(([key, value], index) => {
            returnString += ` ${value}`;
        });
        return returnString.replace(',', ' ');
    }
    else {
        return errors.join('\n\n');
    }
}
