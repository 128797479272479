import snippets from '@/utils/snippets';

export enum FixedSteps {
    InstitutionSite = 1,
    Guidelines = 2,
    ReviewerRelationType = 3,
    Questions = 4,
    GeneralReview = 5,
    PersonalInformation = 6,
}

type EnumMap = Partial<{ [key in FixedSteps]: string }>;

export const fixedStepsDisplayMap: EnumMap = {
    [FixedSteps.InstitutionSite]: snippets.get('create_review_vertical_progress_category1_option1'),
    [FixedSteps.Guidelines]: snippets.get('create_review_vertical_progress_category1_option2'),
    [FixedSteps.ReviewerRelationType]: snippets.get('create_review_vertical_progress_category1_option3'),
    [FixedSteps.Questions]: snippets.get('create_review_vertical_progress_category2'),
    [FixedSteps.GeneralReview]: snippets.get('create_review_vertical_progress_category2_option1'),
    [FixedSteps.PersonalInformation]: snippets.get('create_review_vertical_progress_category3_option1'),
};
