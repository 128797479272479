<script setup lang="ts">
/**
 * The Autosuggest component provides content with the ability to autosuggest some items, which will
 * render a dropdown with items (keyboard navigable and selectable). The dropdown will follow the width of its content.
 * Props of this component control whether the autosuggestion dropdown is open, active and which items it's suggesting.
 *
 * Example usage:
 *  <Autosuggest
 *      @select="select"
 *      :isOpen="true"
 *      :isActive="true"
 *      :items="items"
 *  >
 *      This is the content
 *      <template #item="{item, hasFocus}">
 *          <div :class="{'item--focus': hasFocus}">This is a suggestion item: {item}</div>
 *      </template>
 *  </Autosuggest>
 */

import KeyboardNavigation from '@/components/KeyboardNavigation.vue';
import Icon from '@/components/Icon.vue';
import type { KeyboardNavigationItem } from '@/types/KeyboardNavigationItem';

const props = defineProps({
    /*
    * The items to show in the suggestion
    * */
    items: {
        type: Array<any>,
        default: [],
    },
    /*
    * Whether the suggestion drawer should be open
    * */
    isOpen: {
        type: Boolean,
        default: false,
    },
    /*
    * Whether the suggestion drawer is active (which could be interpreted as "hasFocus")
    * */
    isActive: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['select']);

function select(e: KeyboardNavigationItem) {
    emit('select', e);
}
</script>

<template>
    <div class="c-autosuggest" :class="{ 'c-autosuggest--active': props.isActive }">
        <div class="c-autosuggest__header">
            <Icon role="button" class="c-autosuggest__back" name="chevron-left" />
            <slot />
        </div>
        <div v-if="props.isOpen" class="c-autosuggest__content">
            <slot v-if="!props.items.length" name="empty" />
            <KeyboardNavigation
                v-if="props.items.length"
                v-slot="{ item, hasFocus }"
                :items="props.items"
                @select="select"
            >
                <div class="c-autosuggest__item">
                    <slot name="item" :item="item" :has-focus="hasFocus" />
                </div>
            </KeyboardNavigation>
        </div>
    </div>
</template>
