<script setup lang="ts">
import type { InstitutionSite } from '@core';
import getSuggestionList from '@core/queries/institutionSite/getSuggestionList';
import { reactive } from 'vue';
import type { GetInstitutionSitesListRequest } from '@core/api/institutionSite/requests/GetInstitutionSiteListRequest';
import AutosuggestSearchField from '@/components/AutosuggestSearchField.vue';
import InstitutionSiteMedium from '@/components/items/InstitutionSiteMedium.vue';
import NoResults from '@/components/search-widget/components/NoResults.vue';
import snippets from '@/utils/snippets';

const emit = defineEmits(['update', 'select']);

const request = reactive<GetInstitutionSitesListRequest>({
    name: '',
    excludeUnapprovedInstitutionSites: false,
});
const { isPendingSuggestionList, institutionSites } = getSuggestionList(request);

function onSelect(institutionSite: InstitutionSite) {
    emit('select', institutionSite);
}

function onUpdate(value: string) {
    emit('update', value);
}
</script>

<template>
    <AutosuggestSearchField
        v-model="request.name"
        :placeholder="snippets.get('search_institutionSite')"
        :is-pending="isPendingSuggestionList"
        :items="institutionSites"
        @update:model-value="onUpdate"
        @select="onSelect"
    >
        <template #item="{ item, hasFocus }">
            <InstitutionSiteMedium
                :class="{ 'bg-light-grey': hasFocus }"
                :institution-site="item"
            />
        </template>
        <template #empty>
            <NoResults @select="e => onSelect(e.item)" />
        </template>
    </AutosuggestSearchField>
</template>
