import Close from './Close.vue';
import ViewGrid from './ViewGrid.vue';
import ViewList from './ViewList.vue';
import Check from './Check.vue';
import Info from './Info.vue';
import Search from './Search.vue';
import ChevronUp from './ChevronUp.vue';
import ChevronDown from './ChevronDown.vue';
import ChevronLeft from './ChevronLeft.vue';
import ChevronRight from './ChevronRight.vue';
import Marker from './Marker.vue';
import Phone from './Phone.vue';
import Mail from './Mail.vue';
import ArrowSelector from './ArrowSelector.vue';
import Report from './Report.vue';
import Flag from './Flag.vue';
import Kebab from './Kebab.vue';
import Rating1 from './Rating-1.vue';
import Rating2 from './Rating-2.vue';
import Rating3 from './Rating-3.vue';
import Rating4 from './Rating-4.vue';
import Rating5 from './Rating-5.vue';
import FormProgressIndicator from './FormProgressIndicator.vue';
import FormProgressSkippedIndicator from './FormProgressSkippedIndicator.vue';
import NotificationSuccess from '@/components/icons/NotificationSuccess.vue';
import NotificationInfo from '@/components/icons/NotificationInfo.vue';
import NotificationWarning from '@/components/icons/NotificationWarning.vue';
import NotificationError from '@/components/icons/NotificationError.vue';
import FilterList from '@/components/icons/FilterList.vue';

const icons = {
    'close': Close,
    'check': Check,
    'view-grid': ViewGrid,
    'view-list': ViewList,
    'info': Info,
    'search': Search,
    'chevron-up': ChevronUp,
    'chevron-down': ChevronDown,
    'chevron-left': ChevronLeft,
    'chevron-right': ChevronRight,
    'marker': Marker,
    'phone': Phone,
    'mail': Mail,
    'arrow-selector': ArrowSelector,
    'report': Report,
    'flag': Flag,
    'kebab': Kebab,
    'rating-1': Rating1,
    'rating-2': Rating2,
    'rating-3': Rating3,
    'rating-4': Rating4,
    'rating-5': Rating5,
    'form-progress-indicator': FormProgressIndicator,
    'form-progress-skipped-indicator': FormProgressSkippedIndicator,
    'notification-success': NotificationSuccess,
    'notification-warning': NotificationWarning,
    'notification-error': NotificationError,
    'notification-info': NotificationInfo,
    'filter-list': FilterList,
};

export default icons;
