<script setup lang="ts">
import { computed } from 'vue';

import getSuggestionList from '@core/queries/institutionSite/getSuggestionList';
import snippets from '@/utils/snippets';
import KeyboardNavigation from '@/components/KeyboardNavigation.vue';
import InstitutionSiteMedium from '@/components/items/InstitutionSiteMedium.vue';

const emits = defineEmits(['select']);

const { isPendingSuggestionList, institutionSites } = getSuggestionList();

const suggestionInstitutionSites = computed(() => {
    return (!isPendingSuggestionList.value ? institutionSites.value!.slice(0, 4) : []);
});
</script>

<template>
    <div class="flex flex-col gap-4">
        <div class="p-6">
            {{ snippets.get('no_results_text') }}
        </div>
        <h6 class="px-6 mb-4">
            {{ snippets.get('did_you_mean') }}
        </h6>
        <KeyboardNavigation
            v-if="suggestionInstitutionSites.length"
            v-slot="{ item, hasFocus }"
            :items="suggestionInstitutionSites"
            @select="item => emits('select', item)"
        >
            <InstitutionSiteMedium
                :class="{ 'bg-light-grey': hasFocus }"
                :institution-site="item"
            />
        </KeyboardNavigation>
    </div>
</template>
