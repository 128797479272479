import { Ploc, dependenciesLocator } from '../../../common';

import type { Comparison, ComparisonItem } from '../domain';
import type { PersistentStorageUseCase } from '../../../common/domain/usecases';
import { NotificationSeverity } from '../../notification';
import type { InstitutionType } from '../../../common/types/InstitutionType';
import { ComparisonState } from './ComparisonState';

export class ComparisonPloc extends Ploc<Comparison> {
    /**
     * @param persistentStorage
     */
    constructor(private persistentStorage: PersistentStorageUseCase) {
        super((persistentStorage.get('comparison') || ComparisonState));
    }

    /**
     * Overrides changeState to store state in persistentStorage
     * @param state
     */
    changeState(state: Comparison) {
        super.changeState(state);

        // Store state in persistent storage
        this.persistentStorage.set('comparison', state);
    }

    setItems(addedItems: ComparisonItem[]) {
        this.changeState({
            ...this.state,
            addedItems,
        });
    }

    addItems(addedItems: ComparisonItem[]) {
        this.changeState({
            ...this.state,
            addedItems: [...this.state.addedItems, ...addedItems],
        });
    }

    clearItems() {
        this.changeState({
            ...this.state,
            addedItems: [],
            institutionType: null,
        });
    }

    removeItem(itemId: number) {
        const items = this.state.addedItems.filter(i => i.id !== itemId);

        this.changeState({
            ...this.state,
            addedItems: items,
            institutionType: (!items.length ? null : this.state.institutionType),
        });
    }

    setType(institutionType: InstitutionType) {
        this.changeState({
            ...this.state,
            institutionType,
        });
    }

    /**
     * Opens the floating comparison panel
     */
    openPanel() {
        this.changeState({
            ...this.state,
            isPanelOpen: true,
        });
    }

    /**
     * Closes the floating comparison panel
     */
    closePanel() {
        this.changeState({
            ...this.state,
            isPanelOpen: false,
        });
    }

    hidePanel() {
        this.changeState({
            ...this.state,
            isPanelHidden: true,
        });
    }

    showPanel() {
        this.changeState({
            ...this.state,
            isPanelHidden: false,
        });
    }

    /**
     * Adds an item to the comparison (if it's of the allowed type)
     * @param item
     */
    add(item: ComparisonItem): void {
        const notificationPloc = dependenciesLocator.provideNotificationPloc();

        if (!this.canBeAdded(item)) {
            notificationPloc.notify({
                title: 'Kon niet toevoegen aan de vergelijking',
                text: `Mogelijks heeft de organisatie niet hetzelfde type of bevat je vergelijking reeds ${this.state.max} organisaties.`,
                severity: NotificationSeverity.Warning,
                duration: 5000,
            });
            return;
        }

        notificationPloc.notify({
            title: 'Organisatie toegevoegd aan vergelijking',
            text: `De organisatie werd succesvol toegevoegd aan de vergelijking.`,
            severity: NotificationSeverity.Success,
            duration: 4000,
        });

        this.changeState({
            ...this.state,
            comparisonType: item.institutionType.name,
            comparingItems: [
                ...this.state.comparingItems,
                item,
            ],
        });
    }

    /**
     * Removes an item from the comparison and consequently patches the comparison types
     * @param item
     */
    remove(item: ComparisonItem): void {
        // Remove the item from the comparingItems
        const comparingItems = this.state.comparingItems.filter(i => i.id !== item.id);

        this.changeState({
            ...this.state,
            isPanelOpen: (!comparingItems.length ? false : this.state.isPanelOpen),
            comparisonType: (!comparingItems.length ? '' : this.state.comparisonType),
            comparingItems,
        });
    }

    /**
     * Toggle (add / remove) an item from the comparison
     * @param item
     */
    toggle(item: ComparisonItem): void {
        if (this.isAdded(item)) {
            this.remove(item);
            return;
        }

        this.add(item);
    }

    /**
     * Removes all items from the comparison
     */
    clear(): void {
        this.changeState({
            ...this.state,
            comparingItems: [],
            comparisonType: '',
        });
    }

    /**
     * Removes all items from the selection
     */
    clearSelection(): void {
        this.changeState({
            ...this.state,
            selectedItems: [],
            selectionType: null,
        });
    }

    /**
     * Adds an item to the selection (if it's of the allowed type)
     * @param item
     */
    select(item: ComparisonItem): void {
        if (!this.canBeSelected(item))
            return;

        this.changeState({
            ...this.state,
            selectionType: item.institutionType,
            selectedItems: [
                ...this.state.selectedItems,
                item,
            ],
        });
    }

    /**
     * Removes an item from the selection
     * @param item
     */
    deselect(item: ComparisonItem): void {
        const items = this.state.selectedItems.filter(i => i.id !== item.id);

        this.changeState({
            ...this.state,
            selectedItems: items,
            selectionType: (!items.length ? null : this.state.selectionType),
        });
    }

    /**
     * True if the given item has the right type for the current comparison
     * @param item
     */
    hasAllowedTypeForComparison(item: ComparisonItem) {
        return (this.state.comparisonType === item.institutionType.name);
    }

    /**
     * True if the given item has the right type for the current selection
     * @param item
     */
    hasAllowedTypeForSelection(item: ComparisonItem) {
        return (this.state.selectionType?.name === item.institutionType.name);
    }

    /**
     * True if the item can be added to the comparison
     * @param item
     */
    canBeAdded(item: ComparisonItem): boolean {
        return (
            item.overallScore !== null && (
                this.isEmpty() || (
                    this.hasAllowedTypeForComparison(item)
                        && !this.isAdded(item)
                        && !this.isFull()
                )
            )
        );
    }

    /**
     * True if the item can be added to the selection
     * @param item
     */
    canBeSelected(item: ComparisonItem): boolean {
        return (
            item.overallScore !== null && (
                this.isEmptySelection() || (
                    this.hasAllowedTypeForSelection(item)
                        && !this.isSelected(item)
                        && !this.isFullSelection()
                )
            )
        );
    }

    /**
     * Gets the amount of items being compared
     */
    getCount(): number {
        return this.state.comparingItems.length;
    }

    /**
     * True if the given item is currently being compared
     * @param item
     */
    isAdded(item: ComparisonItem): boolean {
        return Boolean(this.state.comparingItems.find(o => o.id === item.id));
    }

    /**
     * True if nothing is being compared
     */
    isEmpty(): boolean {
        return Boolean(!this.state.comparingItems.length);
    }

    /**
     * True if the max amount of comparing items has been reached
     */
    isFull(): boolean {
        return (this.state.comparingItems.length >= this.state.max);
    }

    /**
     * Gets the amount of selected items
     */
    getSelectedCount(): number {
        return this.state.selectedItems.length;
    }

    /**
     * True if the given item is currently selected
     * @param item
     */
    isSelected(item: ComparisonItem): boolean {
        return Boolean(this.state.selectedItems.find(o => o.id === item.id));
    }

    /**
     * True if nothing is selected
     */
    isEmptySelection(): boolean {
        return Boolean(!this.state.selectedItems.length);
    }

    /**
     * True if the max amount of selected items has been reached
     */
    isFullSelection(): boolean {
        return (this.state.selectedItems.length >= this.state.max);
    }

    enableAddingItems() {
        this.changeState({
            ...this.state,
            isAddingItems: true,
            isMakingNewComparison: false,
        });
    }

    enableMakingNewComparison() {
        this.changeState({
            ...this.state,
            isAddingItems: false,
            isMakingNewComparison: true,
        });
    }
}
