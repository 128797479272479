'use strict';

import snippets from '../data/snippets';

const snippetsUtil = {
    get(key: string, variables: Record<string, string> = {}): string {
        let snippet = key in snippets ? snippets[key as keyof typeof snippets] : '';

        Object.keys(variables).forEach((variableKey) => {
            const variableValue = variables[variableKey];
            const regex = new RegExp(`\\$\\(${variableKey}\\)`, 'g');
            snippet = snippet.replace(regex, variableValue);
        });

        return snippet;
    },
};

export default snippetsUtil;
