<script setup lang="ts">
import { ref } from 'vue';
import type { InstitutionSite } from '@core/api/institutionSite/responses/InstitutionSite';
import { goTo } from '@/utils/url';
import { INSTITUTION_SITE_DETAIL, SEARCH } from '@/data/urls';
import snippets from '@/utils/snippets';

import Button from '@/components/Button.vue';
import InstitutionSiteSuggestionSearch from '@/components/search-widget/components/InstitutionSiteSuggestionSearch.vue';
import CitySuggestionSearch from '@/components/search-widget/components/CitySuggestionSearch.vue';

const searchString = ref('');
const citySearchString = ref('');

function onSubmit() {
    goTo(SEARCH, [{
        search: searchString.value,
        city: citySearchString.value,
    }]);
}

function onUpdateSearchString(value: string) {
    searchString.value = value;
}

function onUpdateCitySearchString(value: string) {
    citySearchString.value = value;
}

function onSelectInstitutionSite(institutionSite: InstitutionSite) {
    goTo(INSTITUTION_SITE_DETAIL, [institutionSite]);
}

function onSelectCity(city: any) {
    goTo(SEARCH, [{
        city: `${city.zipcode}`,
    }]);
}
</script>

<template>
    <form class="p-6 bg-white rounded-[20px] flex flex-col vp9:flex-row items-center gap-4" @submit.prevent="onSubmit">
        <InstitutionSiteSuggestionSearch @update="onUpdateSearchString" @select="onSelectInstitutionSite" />
        <CitySuggestionSearch @update="onUpdateCitySearchString" @select="onSelectCity" />
        <Button :text="snippets.get('search')" type="submit" class="w-full vp9:w-auto" />
    </form>
</template>
