import { api } from '../../common/domain';
import type { Province } from './responses/Province';
import type { City } from './responses/City';

function getCities(params: { search?: string, lat?: number, long?: number } = {}) {
    const {
        search: SearchParameter,
        lat: Lat,
        long: Long,
    } = params;

    return api().get<City[]>(`/location/city`, {
        params: { SearchParameter, Lat, Long },
    });
}

const getProvinces = () => api().get<Province[]>(`location/province`);

export default {
    getCities,
    getProvinces,
};
