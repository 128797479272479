import type { HttpStatusCode } from 'axios';

export class BaseResponse<T> {
    Result?: T;
    Success: boolean;
    Errors: string[] | null;
    StatusCode: HttpStatusCode;

    public constructor(result?: T, statusCode: HttpStatusCode = 200, success: boolean = true) {
        this.Result = result;
        this.StatusCode = statusCode;
        this.Success = success;
        this.Errors = null;
    }

    public AddError(error: string) {
        if (this.Errors == null)
            this.Errors = [error];

        else
            this.Errors.push(error);

        return this;
    }

    public AddErrors(errors: string[]) {
        if (this.Errors == null)
            this.Errors = errors;
        else
            this.Errors.concat(errors);

        return this;
    }
}
