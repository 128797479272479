<script setup lang="ts">
import { ref } from 'vue';

import Autosuggest from '@/components/Autosuggest.vue';
import SearchField from '@/components/SearchField.vue';
import type { KeyboardNavigationItem } from '@/types/KeyboardNavigationItem';

const props = defineProps({
    isPending: {
        type: Boolean,
    },
    items: {
        type: Array<any>,
        default: [],
    },
    placeholder: {
        type: String,
    },
    autofocus: {
        type: Boolean,
    },
});

const emit = defineEmits(['select', 'change']);

const searchString = defineModel<string>({ default: '' });
const isActive = ref(false);
const isOpen = ref(false);

const open = () => isOpen.value = true;
const close = () => isOpen.value = false;
function setActive() {
    document.body.classList.add('hide-header');
    isActive.value = true;
}
function setInactive() {
    document.body.classList.remove('hide-header');
    isActive.value = false;
}

function select(e: KeyboardNavigationItem) {
    emit('select', e.item);
    close();
    setInactive();
    searchString.value = '';
}

function focus() {
    setActive();
    if (searchString.value.length > 2)
        open();
}

function blur() {
    close();
    setInactive();
}

function update() {
    setActive();
    if (searchString.value.length > 2) {
        open();
        return;
    }

    close();
}
</script>

<template>
    <Autosuggest
        :is-open="isOpen"
        :is-active="isActive"
        :items="items"
        @select="select"
    >
        <SearchField
            v-model="searchString"
            :placeholder="props.placeholder"
            :autofocus="autofocus"
            @update="update"
            @focus="focus"
            @blur="blur"
        />
        <template #item="{ item, hasFocus }">
            <slot name="item" :item="item" :has-focus="hasFocus" />
        </template>
        <template #empty>
            <slot name="empty" />
        </template>
    </Autosuggest>
</template>
