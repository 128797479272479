import { useQuery } from '@tanstack/vue-query';

import { institutionSiteApi } from '../../api';
import { queryKeys } from '../../common/utils/constants';
import type { GetInstitutionSitesListRequest } from '../../api/institutionSite/requests/GetInstitutionSiteListRequest';

export default function getSuggestionList(request: GetInstitutionSitesListRequest) {
    const { data, isPending } = useQuery({
        queryKey: [queryKeys.public.institutionSite.GET_INSTITUTION_SUGGESTION_LIST, request],
        queryFn: () => institutionSiteApi.getSuggestionList(request),
        enabled: () => request?.name !== undefined && request.name.length > 2,
        placeholderData: prev => prev,
        throwOnError: true,
        staleTime: 60 * 1000,
        select: response => response!.data,
    });

    return {
        isPendingSuggestionList: isPending,
        institutionSites: data,
    };
}
