import { useQuery } from '@tanstack/vue-query';

import { locationApi } from '../../api';
import { queryKeys } from '../../common/utils/constants';

export default function useGetCitiesByLatLong(lat: any, long: any) {
    const { data, isPending } = useQuery({
        queryKey: [queryKeys.public.location.SEARCH_BY_LAT_LONG, lat, long],
        queryFn: () => locationApi.getCities({ lat: lat.value, long: long.value }),
        enabled: () => lat.value !== 0 && long.value !== 0,
        placeholderData: prev => prev,
        throwOnError: true,
        staleTime: 60 * 1000,
        select: (response) => {
            return response!.data;
        },
    });

    return {
        isPendingCitiesByLatLong: isPending,
        cities: data,
    };
}
