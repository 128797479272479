import { institutionSiteApi, reviewApi } from '../../../../api';
import type {
    CreateReviewRequest,
    RemarkDictionary,
    ScoreDictionary,
} from '../../../../api/review/requests/CreateReviewRequest';
import type { CreateReview } from '../entities';
import type { UpdateReviewRequest } from '../../../../api/review/requests/UpdateReviewRequest';

export class CreateReviewUseCase {
    async getDataForReview(id: number) {
        const response = await institutionSiteApi.getDataForReview(id);

        return response.Result!;
    }

    async createReview(state: CreateReview) {
        const request: CreateReviewRequest = {
            institutionSiteId: state.institutionSite!.id,
            lastName: state.personalInfo.lastName,
            firstName: state.personalInfo.firstName,
            mailAddress: state.personalInfo.mailAddress,
            phoneNumber: state.personalInfo.phoneNumber,
            experienceText: state.generalReview,
            reviewerRelationType: state.reviewerRelationType,
            customReviewerRelationType: state.reviewerRelationType === 'Andere' && state.customReviewerRelationType !== '' ? state.customReviewerRelationType : null,
            scores: state.questions.reduce<ScoreDictionary>((acc, current) => {
                acc[current.id] = current.userScore;
                return acc;
            }, {}),
            remarks: state.questions.reduce<RemarkDictionary>((acc, current) => {
                acc[current.id] = current.userRemark;
                return acc;
            }, {}),
            reCaptchaToken: state.reCaptchaToken,
        };

        await reviewApi.createReview(request);
    }

    async updateReview(state: CreateReview) {
        const request: UpdateReviewRequest = {
            reviewId: state.updateReviewId!,
            editToken: state.editToken!,
            experienceText: state.generalReview,
            scores: state.questions.reduce<ScoreDictionary>((acc, current) => {
                acc[current.id] = current.userScore;
                return acc;
            }, {}),
            remarks: state.questions.reduce<RemarkDictionary>((acc, current) => {
                acc[current.id] = current.userRemark;
                return acc;
            }, {}),
        };

        await reviewApi.updateReview(request);
    }
}
