const http = {
    setQueryParams(params: Record<string, any>) {
        const url = new URL(window.location.href.split('?')[0]);

        for (const key in params) {
            if (Array.isArray(params[key])) {
                params[key].forEach((paramValue: string) => {
                    url.searchParams.append(key, paramValue);
                });
                continue;
            }
            url.searchParams.set(key, params[key]);
        }

        window.history.replaceState({}, '', url);
    },
    query(params: Record<string, any>): string {
        const queryParams: string[] = [];

        for (const [key, value] of Object.entries(params)) {
            // Don't add empty strings to the query params
            if (value === '')
                continue;

            if (Array.isArray(value)) {
                value.forEach(v => queryParams.push(`${key}=${v}`));
                continue;
            }

            queryParams.push(`${key}=${value}`);
        }

        return queryParams.length ? `?${queryParams.join('&')}` : '';
    },
};

export default http;
