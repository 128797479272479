const snippets = {

    // General
    close: 'Sluiten',
    cancel: 'Annuleren',
    confirm: 'Bevestigen',
    show_more: 'Meer tonen',
    show_less: 'Minder tonen',
    newest: 'Nieuwste',
    oldest: 'Oudste',
    optional: '(optioneel)',

    // Search
    search: 'Zoeken',
    search_results: 'Zoekresultaten',
    sort_by: 'Sorteren op',
    search_institutionSite: 'Zoek een organisatie',
    search_by_location: 'Zoek op gemeente',
    in_my_area: 'In mijn buurt',
    clear_filters: 'Filters wissen',
    no_results_text: 'We hebben helaas geen organisaties gevonden voor jouw zoekopdracht.',
    did_you_mean: 'Bedoelde je een van deze organisaties?',
    search_type: 'Sector',
    search_region: 'Regio',
    search_overall_score: 'Waardering',
    institutionSite_search_header_title: 'Vertel ons over jouw ervaring met een organisatie',
    no_location_found: 'Geen locatie gevonden',

    // Institution sites
    view_on_map: 'Kijk op kaart',
    contactinfo: 'Contact',
    no_average_score: 'Nog geen gemiddelde score',

    // Reviews
    review: 'waardering',
    reviews: 'waarderingen',
    comment_by: 'Reactie van',
    flag: 'Rapporteer',
    flag_review: 'Rapporteer deze waardering',
    report: 'Melding maken',
    load_more_reviews: 'Laad meer waarderingen',
    review_reportReason_text: 'Waarom rapporteer je deze waardering?',

    // Comparison
    compare_institutionSite: 'Vergelijk organisatie',
    compare_institutionSites: 'Vergelijk organisaties',
    comparison_rating_title: 'Totaal',
    view_comparison: 'Bekijk vergelijking',
    start_new_comparison: 'Start nieuwe vergelijking',
    add_to_comparison: 'Voeg organisaties toe',
    same_type_comparison_note: 'Je kan enkel organisaties van hetzelfde type vergelijken.',

    // User location
    user_location_error_title: 'Kon jouw locatie niet bepalen',
    user_location_error_text: 'Gelieve locatie delen toe te staan. Jouw precieze locatie wordt gedeeld zodat er organisaties bij jou in de buurt kunnen worden getoond. We gebruiken deze info enkel en alleen voor deze functie.',

    // Create review
    create_review_previous: 'Terug',
    create_review_next: 'Volgende',
    create_review_search_institutionSite: 'Zoek op naam organisatie',
    create_review_select_institutionSite_title: 'Kies een organisatie',
    create_review_select_institutionSite_subtitle: 'Zoek via onderstaande zoekbalk de organisatie die jij wil waarderen',
    create_review_select_institutionSite_previous: 'Annuleren',
    create_review_select_institutionSite_next: 'Waardeer deze organisatie',
    create_review_selected_institutionSite: 'Is dit de organisatie die je wil waarderen?',
    create_review_accept_guidelines_alert: 'Een waardering is geen klacht. Heb je wel een klacht over een organisatie? Neem dan eerst rechtstreeks contact op met de organisatie. Voor meer informatie omtrent klachten kun je <a href="$(complaintUrl)" target="_blank">hier</a> terecht.',
    create_review_accept_guidelines_alert_helpline: 'Wil je melding maken van een verontrustende situatie, neem contact op met <a href="$(helpLineUrl)" target="_blank">1712</a>. Zij bekijken samen met jou wat je best kan doen. Het Waarderingsplatform kan hierin geen actie ondernemen.',
    create_review_accept_guidelines_title: 'Hoe schrijf je een waardering?',
    create_review_accept_guidelines_highlight: 'Een waardering is geen klacht. Heb je wel een klacht over een organisatie? Neem dan eerst rechtstreeks contact op met de organisatie. Voor meer informatie omtrent klachten kun je hier terecht.',
    create_review_accept_guidelines_card_title: 'Geen zorgen, wij voorzien uitleg tijdens het invullen',
    create_review_accept_guidelines_card_description: 'Volg de stappen met uitleg en beantwoord minstens <b> $(minRequiredAnswers) van $(totalQuestions) vragen</b> <br> Voor het schrijven van een waardering, vragen we je rekening te houden met onze richtlijnen. Lees deze richtlijnen op deze pagina. <br>Jouw waardering is volledig anoniem',
    create_review_accept_guidelines_card_cta: 'Bekijk de richtlijnen hier',
    create_review_accept_guidelines_previous: 'Terug',
    create_review_accept_guidelines_next: 'Ik begrijp het',
    create_review_reviewer_capacity_title: 'Wie ben jij?',
    create_review_reviewer_capacity_subtitle: 'Alvorens je je waardering invult, willen we je vragen om je kort voor te stellen. Dit zal andere gebruikers helpen om je waardering beter in context te plaatsen.',
    create_review_reviewer_capacity_support_question: 'Ben jij zelf de persoon die de zorg of ondersteuning gebruikt?',
    create_review_reviewer_capacity_support_answerYes: 'Ja',
    create_review_reviewer_capacity_support_answerNo: 'Nee',
    create_review_reviewer_capacity_capacity_name_question: 'Wat is jouw relatie met de persoon die de zorg of ondersteuning verkreeg?',
    create_review_reviewer_capacity_capacity_name_placeholder: 'Maak een keuze',
    create_review_reviewer_other_field_title: 'Geef aan welke relatie je hebt met je zorgbehoevende (optioneel)',
    create_review_reviewer_other_field_placeholder: 'Vul hier in wat jouw relatie is met de persoon die de zorg of ondersteuning verkreeg',
    create_review_questions_remark_placeholder: 'Deze aanvullende informatie wordt niet publiek geplaatst, maar wordt wel bezorgd aan de organisatie. Dit kan voor hen handig zijn om je waardering beter te begrijpen.',
    create_review_general_average_score_title: 'Gemiddelde score op basis van jouw antwoorden:',
    create_review_general_review_title: 'Jouw algemene waardering',
    create_review_general_review_subtitle: 'Beschrijf in je eigen woorden: <br><ul style="text-align: left;"><li>Wat vind je van de zorg of ondersteuning die je kreeg?</li><li>Hoe was het voor jou?</li><li>Heb je nog andere opmerkingen?</li><li>Heb je tips voor de mensen in de zorg of ondersteuning?</li></ul>',
    create_review_general_review_placeholder: 'Beschrijf hier in je eigen woorden',
    create_review_general_personalinfo_title: 'Wie ben jij?',
    create_review_general_personalinfo_alert: 'We hebben nog enkele gegevens nodig zodat we jou op de hoogte kunnen houden van de status van jouw waardering en indien nodig contact kunnen opnemen. <b>Jouw persoonlijke gegevens worden niet gedeeld</b> met de organisatie of publiek gezet.',
    create_review_general_guidelines_title: 'Wij controleren de richtlijnen terwijl jij schrijft',
    create_review_general_guidelines_option1: 'Blijf beleefd',
    create_review_general_guidelines_option2: 'Noem geen namen van personen',
    create_review_general_guidelines_option3: 'Maak geen reclame',
    create_review_submit: 'Waardering versturen',
    create_review_confirmation_title: 'We hebben jouw waardering goed ontvangen',
    create_review_confirmation_description_title: 'Hartelijk bedankt!',
    create_review_confirmation_description_text: 'Jouw waardering werd succesvol verstuurd. Onze redactie kijkt alles nog eens na en zal jou een mailtje sturen van zodra de waardering is goedgekeurd. Nadien dien je deze zelf te publiceren via de ontvangen mail.',
    create_review_confirmation_close: 'Naar de homepagina',
    create_review_vertical_progress_category1: 'Wie',
    create_review_vertical_progress_category1_option1: 'Organisatie kiezen',
    create_review_vertical_progress_category1_option2: 'Richtlijnen',
    create_review_vertical_progress_category1_option3: 'Wie ben ik',
    create_review_vertical_progress_category2: 'Waardering',
    create_review_vertical_progress_category2_option1: 'Algemene waardering',
    create_review_vertical_progress_category3: 'Blijf op de hoogte',
    create_review_vertical_progress_category3_option1: 'Persoonlijke informatie',
    create_review_vertical_progress_category3_option2: 'Bevestig jouw e-mailadres',
    create_review_header_navigation: 'Navigatie',
    create_review_header_stop: 'Waardering stopzetten',
    create_review_no_skippable_questions_title: 'Je kan geen vragen meer overslaan',
    create_review_no_skippable_questions_description: 'Je moet minstens <b>$(minRequiredAnswers) van de $(totalQuestions) vragen</b> invullen. Op basis daarvan kunnen we een correcte score toekennen aan deze organisatie. Jij hebt voorlopig $(currentRequiredAnswers) van de $(minRequiredAnswers) verplichte vragen ingevuld.',
    create_review_guidelines_alert: '',
    create_review_general_personalinfo_consent: 'Ik bevestig kennis genomen te hebben van en akkoord te gaan met de gebruiksvoorwaarden van het waarderingsplatform.',
    create_review_general_personalinfo_declaration: 'Ik verklaar op eer dat ik deze waardering eerlijk heb ingevuld.',
    create_review_general_personalinfo_confirm_personal_info: 'Ik geef toestemming voor het verwerken van de door mij opgegeven persoonsgegevens voor het verwerken en, indien aan alle gebruiksvoorwaarden en richtlijnen voldaan is, plaatsen van mijn waardering.',
    publish_review_confirmation_description_title: 'Hartelijk bedankt!',
    publish_review_confirmation_title: 'Jouw waardering staat online',
    publish_review_confirmation_description_text: 'Jouw waardering werd succesvol gepubliceerd.',
    publish_review_confirmation_close: 'Naar de homepagina',

    // Review Question
    review_question_score_explanation: 'Wil je je score toelichten?',

    // Tooltip
    tooltip_toggle_institutionSite: 'Je kunt deze organisatie niet vergelijken omdat deze afkomstig is uit een andere sector dan de organisatie die je al hebt aangeduid.',
    tooltip_toggle_institutionSite_max_length: 'Je kunt maximaal 3 organisaties vergelijken met elkaar',
    tooltip_skip_question: 'Weet je het antwoord niet op deze vraag? Sla deze dan over. Je kan maximaal drie vragen overslaan. Beantwoord dus minstens $(minRequiredAnswers) van de $(totalQuestions) vragen tijdens het invullen.',
    tooltip_institutionSite_no_score: 'Om tot een representatieve score te komen, kennen we pas een gemiddelde score toe vanaf 5 waarderingen',
    tooltip_start_comparison: 'Je moet minstens $(minValue) $(organisationSuffix) toevoegen om een vergelijking te starten.',

    // Placeholder
    institutionSite_placeholder_title: 'We hebben niet gevonden wat jij zoekt',
    institutionSite_placeholder_description: 'Dit platform is in ontwikkeling waardoor het nog niet alle organisaties weergeeft. Wil je graag jouw organisatie voor zorg en ondersteuning waarderen maar kan je die niet terugvinden? Neem dan zeker contact met ons op via contact@waarderingsplatform.be.',
    comparison_placeholder_title: 'Geen organisatie geselecteerd',
    comparison_placeholder_description: 'Voeg organisaties toe via onderstaande knop om van start te gaan met je vergelijking',
    comparison_placeholder_button_title: 'Voeg organisaties toe',

    // Notification
    notification_review_report_success_title: 'Melding verstuurd',
    notification_review_report_success_description: 'We hebben je melding ontvangen en gaan er zo snel mogelijk mee aan de slag.',
    notification_share_button_title: 'Pagina gekopieerd',
    notification_share_button_text: 'De url van deze pagina staat nu op je klembord',

    // Create review comment
    create_review_comment_title: 'Reageren op waardering',
    create_review_comment_subtitle: 'Je kan hier reageren op de waardering die op $(createDate) geplaatst werd voor $(institutionSiteName)',
    create_review_comment_submit: 'Reactie versturen',
    create_review_comment_score_title: 'Gemiddelde score op basis van de antwoorden:',
    create_review_comment_review_title: 'Waardering',
    create_review_comment_text_title: 'Schrijf hier jouw reactie',
    create_review_comment_text_placeholder: 'Typ hier jouw reactie',

    // Edit review comment
    edit_review_comment_submit: 'Pas je reactie aan',
    edit_review_comment_title: 'Reactie aanpassen',
    edit_review_comment_subtitle: 'Hieronder kan je jouw reactie die je op $(createDate) plaatste voor $(institutionSiteName) aanpassen.',

    // Contact
    contact_firstName: 'Voornaam',
    contact_lastName: 'Achternaam',
    contact_email: 'E-mailadres',
    contact_phone: 'GSM of telefoonnummer (optioneel)',
    contact_message: 'Bericht',
    contact_submit: 'Versturen',
    contact_confirmation_title: 'We hebben je bericht goed ontvangen!',
    contact_confirmation_description_title: 'Bedankt voor jouw bericht, dit is goed verzonden.',
    contact_confirmation_description_text: 'De beheerder van het waarderingsplatform zal jouw bericht zo snel mogelijk bekijken en komt dan bij je terug.',
    contact_confirmation_close: 'Afsluiten',
};
export default snippets;
