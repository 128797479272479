import { handleApiError } from '../domain/ErrorHandling';
import type { ErrorState } from './CommonStates';

type Subscription<S> = (state: S) => void;

export abstract class Ploc<S> {
    private internalState: S;
    private listeners: Subscription<S>[] = [];

    protected constructor(initialState: S) {
        this.internalState = initialState;
    }

    public get state(): S {
        return this.internalState;
    }

    public set state(state: S) {
        this.internalState = state;
    }

    changeState(state: S) {
        if (this.listeners.length > 0)
            this.listeners.forEach(listener => listener(state));
    }

    subscribe(listener: Subscription<S>) {
        this.listeners.push(listener);
    }

    unsubscribe(listener: Subscription<S>) {
        const index = this.listeners.indexOf(listener);
        if (index > -1)
            this.listeners.splice(index, 1);
    }

    mapToErrorState(error: any): ErrorState {
        return {
            kind: 'ErrorState',
            error: handleApiError(error),
        };
    }
}
