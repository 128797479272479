import { useQuery } from '@tanstack/vue-query';

import { locationApi } from '../../api';
import { queryKeys } from '../../common/utils/constants';

export default function useGetCitiesBySearchString(searchString: any) {
    const { data, isPending } = useQuery({
        queryKey: [queryKeys.public.location.SEARCH_BY_STRING, searchString],
        queryFn: () => locationApi.getCities({ search: searchString.value }),
        enabled: () => searchString?.value.length > 2,
        placeholderData: prev => prev,
        throwOnError: true,
        staleTime: 60 * 1000,
        select: (response) => {
            return response!.data;
        },
    });

    return {
        isPendingCitiesByString: isPending,
        cities: data,
    };
}
