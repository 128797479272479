import type { BaseResponse, PagedResponse } from '../../../../common/domain';
import { institutionSiteApi } from '../../../../api';
import type { InstitutionSite } from '../entities/InstitutionSite';

export class InstitutionSitesUseCase {
    async get(
        page: number,
        searchString: string,
        types: string[],
        provinces: string[],
        customCityString: string,
        reviewScore: number,
        sortProperty: string,
    ) {
        const response: BaseResponse<PagedResponse<InstitutionSite[]>> = await institutionSiteApi.get({
            page,
            pageSize: 10,
            searchString,
            types,
            provinces,
            customCityString,
            minScore: reviewScore,
            sortProperty,
        });

        const results = response.Result!.results.map((institutionSite: any) => {
            return {
                ...institutionSite,
                institutionType: institutionSite.institutionType,
                type: institutionSite.institutionType?.name,
            };
        });

        return { results, pagination: response.Result!.pagination };
    }
}
