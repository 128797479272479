import { Ploc } from '../../../common';

import type { InstitutionSitesUseCase, Search, SectorsUseCase } from '../domain';
import { SearchState } from './SearchState';
import { mapSectorParamsToState } from './utils/map-sector-params-to-state';

export class SearchPloc extends Ploc<Search> {
    constructor(
        private institutionSitesUseCase: InstitutionSitesUseCase,
        private sectorsUseCase: SectorsUseCase,
    ) {
        super(SearchState);
    }

    /**
     * Loads the sectors and their types for the type filter
     */
    async loadSectors() {
        const sectorFilters = await this.sectorsUseCase.getFilterNodes();

        this.changeState({
            ...this.state,
            sectorFilters,
            selectedSectorFilters: mapSectorParamsToState(sectorFilters, this.state.sectorFilterParams),
        });
    }

    async loadInstitutionSites() {
        this.changeState({
            ...this.state,
            isLoading: true,
        });

        const response = await this.institutionSitesUseCase.get(
            this.state.page,
            this.state.searchString,
            this.state.selectedTypes,
            this.state.selectedProvinces,
            this.state.customCityString,
            this.state.selectedReviewScore,
            this.state.sortBy,
        );

        this.changeState({
            ...this.state,
            institutionSites: response.results,
            pagination: response.pagination,
            isLoading: false,
        });
    }

    /**
     * @param params
     */
    setParameters(params = {}) {
        this.changeState({
            ...this.state,
            ...params,
        });
    }

    /**
     *
     * @param values
     */
    setSelectedSectorFilters(values: any) {
        const types: string[] = Object.keys(values).filter((typeName) => {
            return values[typeName].checked;
        });

        this.changeState({
            ...this.state,
            selectedSectorFilters: values,
            selectedTypes: types,
            page: 1,
        });

        this.loadInstitutionSites();
    }

    /**
     * Sets the search string
     * @param searchString
     */
    setSearchString(searchString: string) {
        this.changeState({
            ...this.state,
            searchString,
        });
    }

    /**
     * Sets the page (pagination)
     * @param page
     */
    setPage(page: number) {
        this.changeState({
            ...this.state,
            page,
        });

        this.loadInstitutionSites();
    }

    /**
     * Select the given provinces, this will be reflected in the location filter
     * @param provinces
     */
    selectProvinces(provinces: string[]) {
        this.changeState({
            ...this.state,
            page: 1,
            selectedProvinces: provinces,
            isSearchingByCityString: false,
            customCityString: '',
        });

        this.loadInstitutionSites();
    }

    /**
     * @param reviewScore
     */
    selectReviewScores(reviewScore: any) {
        this.changeState({
            ...this.state,
            page: 1,
            selectedReviewScore: reviewScore,
        });

        this.loadInstitutionSites();
    }

    /**
     * Enable custom city searching by string (location filter)
     */
    enableSearchByCityString() {
        const prevProvinces = this.state.selectedProvinces;

        this.changeState({
            ...this.state,
            selectedProvinces: [],
            isSearchingByCityString: true,
        });

        if (prevProvinces.length)
            this.loadInstitutionSites();
    }

    /**
     * Disable custom city searching by string (location filter)
     */
    disableSearchByCityString() {
        const prevCustomCityString = this.state.customCityString;

        this.changeState({
            ...this.state,
            selectedProvinces: [],
            isSearchingByCityString: false,
            customCityString: '',
        });

        if (prevCustomCityString.length)
            this.loadInstitutionSites();
    }

    /**
     * Sets the custom city search string (location filter)
     * @param searchString
     */
    setCityString(searchString: string) {
        this.changeState({
            ...this.state,
            page: 1,
            isSearchingByCityString: true,
            customCityString: searchString,
        });
    }

    /**
     * Searches by string
     */
    searchByString(searchString: string) {
        this.changeState({
            ...this.state,
            page: 1,
            searchString,
        });

        if (!searchString.length || searchString.length >= 2)
            this.loadInstitutionSites();
    }

    /**
     * Clears all filter data
     */
    clearFilters() {
        this.changeState({
            ...this.state,
            page: 1,
            searchString: '',
            selectedProvinces: [],
            selectedSectorFilters: {},
            selectedTypes: [],
            isSearchingByCityString: false,
            customCityString: '',
            selectedReviewScore: 0,
        });

        this.loadInstitutionSites();
    }

    /**
     * Set the view mode in which the search results will be displayed
     * @param mode
     */
    setViewMode(mode: string) {
        this.changeState({
            ...this.state,
            viewMode: mode,
        });
    }

    /**
     *
     * @param sortProperty
     */
    sortBy(sortProperty: string) {
        this.changeState({
            ...this.state,
            sortBy: sortProperty,
            page: 1,
        });

        this.loadInstitutionSites();
    }

    /**
     * Get the amount of filters that are currently being used
     */
    getActiveFiltersCount(): number {
        let count = 0;

        if (this.state.selectedProvinces.length)
            count++;

        if (this.state.isSearchingByCityString)
            count++;

        if (this.state.selectedTypes.length)
            count++;

        if (this.state.selectedReviewScore > 0)
            count++;

        return count;
    }
}
