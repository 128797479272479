import { Ploc } from '../../../common';

import type { Notification, NotificationStack } from '../domain';
import { NotificationState } from './NotificationState';

export class NotificationPloc extends Ploc<NotificationStack> {
    constructor() {
        super(NotificationState);
    }

    /**
     * Notifies the user with a notification
     */
    notify(notification: Notification) {
        const notifications = this.state.notifications;
        if (notifications.length >= this.state.max)
            notifications.shift();

        const lastNotificationId = this.state.lastNotificationId + 1;

        notification.id = lastNotificationId;

        this.changeState({
            ...this.state,
            lastNotificationId,
            notifications: [
                ...notifications,
                notification,
            ],
        });

        if (notification.duration)
            setTimeout(() => this.remove(notification), notification.duration);
    }

    /**
     * Removes the notification from the stack
     */
    remove(notification: Notification) {
        if (!notification.id)
            return;

        this.changeState({
            ...this.state,
            notifications: this.state.notifications.filter(n => n.id !== notification.id),
        });
    }
}
