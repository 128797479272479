import type { AxiosInstance, AxiosResponse } from 'axios';
import axios from 'axios';
import { BaseResponse } from './BaseResponse';

let apiInstance: AxiosInstance = axios.create({
    withCredentials: true,
    timeout: 360000,
});

export function api(): AxiosInstance {
    return apiInstance;
}

export function createPromise<T>(axiosRequest: Promise<AxiosResponse<any, any>>): Promise<BaseResponse<T>> {
    return new Promise((resolve, _reject) => {
        axiosRequest
            .then((response) => {
                if (response.request.statusCode >= 400 && response.request.StatusCode < 600)
                    _reject(new BaseResponse(null as T, response.request.statusCode, false).AddErrors(response.request.error));
                else
                    resolve(new BaseResponse(response.data));
            })
            .catch((error) => {
                if (Object.prototype.hasOwnProperty.call(error.response.data, 'summary'))
                    _reject(new BaseResponse(null as T, error.response.status, false).AddErrors(error.response.data.summary));
                else
                    _reject(new BaseResponse(null as T, error.response.status, false).AddErrors(error.response.data.errors));
            });
    });
}

export function configureApi(baseUrl: string, headers: { [key: string]: string }) {
    apiInstance = axios.create({
        baseURL: baseUrl,
        headers,
        timeout: 360000,
        paramsSerializer: {
            indexes: null,
        },
    });
}
