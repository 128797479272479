import type { Comparison } from '../domain';

export const ComparisonState: Comparison = {
    addedItems: [],
    institutionType: null,
    isPanelOpen: false,
    isPanelHidden: false,
    max: 3,
    comparisonType: '',
    comparingItems: [],
    selectionType: '',
    selectedItems: [],
    isAddingItems: false,
    isMakingNewComparison: false,
};
