export const queryKeys = {
    redaction: {
        institutionSite: {
            GET_INSTITUTION_SITES: 'getInstitutionSitesForRedaction',
        },
        reviewComment: {
            GET_REVIEW_COMMENTS: 'getReviewCommentsForRedaction',
            GET_REVIEW_COMMENT_DETAIL: 'getReviewCommentDetailForRedaction',
        },
        review: {
            GET_REVIEWS: 'getReviewsForRedaction',
            GET_REVIEW_DETAIL: 'getReviewDetailForRedaction',
        },
        combinedReview: {
            GET_COMBINED_REVIEW_DATA: 'getCombinedReviewDataForRedaction',
            GET_EXPORT_DATA: 'getExportDataForRedaction',
        },
    },
    public: {
        institutionSite: {
            GET_INSTITUTION_SITES_FOR_COMPARISON: 'getInstitutionSitesForComparison',
            GET_INSTITUTION_SUGGESTION_LIST: 'getInstitutionSuggestionList',
            GET_INSTITUTION_SITE_DETAIL: 'getInstitutionSiteDetail',
            GET_INSTITUTION_SITE_DETAIL_REVIEWS: 'getInstitutionSiteDetailReviews',
        },
        location: {
            SEARCH_BY_LAT_LONG: 'getLocationByLatLong',
            SEARCH_BY_STRING: 'getLocationBySearchString',
            GET_PROVINCES: 'getProvinces',
        },
        review: {
            GET_REVIEWER_RELATIONTYPES: 'getReviewerRelationTypes',
            GET_REVIEW_FOR_EDIT: 'getReviewForEdit',
            GET_REVIEW_REPORT_REASONS: 'getReviewReportReasons',
        },
        reviewComment: {
            GET_REVIEW_FOR_CREATE_COMMENT: 'getReviewForCreateComment',
            GET_REVIEW_COMMENT_FOR_EDIT: 'getReviewCommentForEdit',
        },
    },
};
