export enum NotificationSeverity {
    Success = 'success',
    Warning = 'warning',
    Error = 'error',
    Info = 'info',
}

export interface Notification {
    /*
    * The id of the notification, needed to remove the notification
    * */
    id?: number

    /*
    * The short title of the notification
    * */
    title: string

    /*
    * The longer text of the notification
    * */
    text: string

    /*
    * The severity of the notification
    * */
    severity: NotificationSeverity

    /*
    * The duration before the notification disappears
    * */
    duration?: number
}
