<script setup lang="ts">
/**
 * This component renders its contents in an overlay. Setting property autoClose to false will disable
 * clicking the overlay to close it.
 *
 * Example usage:
 * <Overlay :autoClose="false">
 *     <div>This is overlayed content</div>
 * </Overlay>
 */

import { ref } from 'vue';

const props = defineProps({
    /*
    * When true, the overlay will emit "close" when the user clicks the overlay
    * */
    autoClose: {
        type: Boolean,
        default: true,
    },
});

const emits = defineEmits(['close']);

const contentElement = ref<HTMLElement>();

function close() {
    if (props.autoClose)
        emits('close');
}

function overlayClick(e: MouseEvent) {
    if (contentElement.value && !contentElement.value.contains(e.target as Node))
        close();
}
</script>

<template>
    <div class="c-overlay--content" @click="overlayClick">
        <div ref="contentElement" class="c-overlay--content-box">
            <slot />
        </div>
    </div>
    <div class="c-overlay" />
</template>
