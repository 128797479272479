<script setup lang="ts">
import { onMounted, ref } from 'vue';
import Icon from '@/components/Icon.vue';

const props = defineProps({
    autofocus: {
        type: Boolean,
    },
    debounce: {
        type: Boolean,
        default: true,
    },
    debounceTime: {
        type: Number,
        default: 200,
    },
    icon: {
        type: String,
        default: 'search',
    },
    placeholder: {
        type: String,
    },
});

const emit = defineEmits(['debouncedUpdate', 'update', 'typing', 'blur', 'focus']);
const searchString = defineModel<string>();

let debounceTimeout: NodeJS.Timeout;

function change() {
    emit('update', searchString);
    if (props.debounce) {
        clearTimeout(debounceTimeout);
        debounceTimeout = setTimeout(() => emit('debouncedUpdate'), props.debounceTime);
    }
}

const searchField = ref<HTMLInputElement>();

onMounted(() => {
    if (props.autofocus)
        searchField.value!.focus();
});
</script>

<template>
    <div class="c-search-field">
        <Icon class="c-search-field__icon" :name="props.icon" />
        <input
            ref="searchField"
            v-model="searchString"
            :placeholder="props.placeholder"
            class="c-search-field__input"
            @blur="emit('blur')"
            @focus="emit('focus')"
            @keyup="change"
        >
    </div>
</template>
