import type { Search } from '../domain';

export const SearchState: Search = {
    isLoading: true,
    searchString: '',
    sortBy: 'name',
    viewMode: 'default',
    page: 1,
    institutionSites: [],
    provinces: [],

    sectorFilters: [],
    selectedSectorFilters: {},
    sectorFilterParams: [],

    selectedTypes: [],
    selectedProvinces: [],
    isSearchingByCityString: false,
    selectedReviewScore: 0,
    customCityString: '',
    pagination: {
        amountOfPages: 0,
        currentPage: 1,
        firstRowOnPage: 0,
        lastRowOnPage: 0,
        pageSize: 0,
        totalAmount: 0,
    },
};
