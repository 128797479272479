<script setup lang="ts">
/**
 * This component renders a list of items by using its slot (property "item" gives access to the current item in the array)
 * and wraps it in keyboard navigation, making it possible to use arrow-keys to navigate the list.
 *
 * Example usage:
 * <KeyboardNavigation :items="['Nice']" v-slot="{ item, hasFocus }">
 *     <div class="c-item" :class="{'c-item--focus': hasFocus}">
 *         {{ item }}
 *     </div>
 * </KeyboardNavigation>
 *
 */
import { onBeforeUnmount, onMounted, ref } from 'vue';

import type { KeyboardNavigationItem } from '@/types/KeyboardNavigationItem';

const props = defineProps({
    items: {
        type: Array<any>,
        default: [],
    },
});

const emits = defineEmits(['select']);

const focusIndex = ref(-1);

onMounted(() => document.addEventListener('keydown', onKeyDown));
onBeforeUnmount(() => document.removeEventListener('keydown', onKeyDown));

function onKeyDown(e: KeyboardEvent) {
    if (e.key === 'Enter' && focusIndex.value !== -1) {
        e.preventDefault();
        select(focusIndex.value);
        return;
    }

    if (e.key === 'ArrowDown') {
        e.preventDefault();
        focusNext();
        return;
    }

    if (e.key === 'ArrowUp') {
        e.preventDefault();
        focusPrev();
    }
}

function focusPrev() {
    const prevIndex = focusIndex.value - 1;
    focus(prevIndex < -1 ? props.items.length - 1 : prevIndex);
}

function focusNext() {
    const nextIndex = focusIndex.value + 1;
    focus(nextIndex > props.items.length - 1 ? 0 : nextIndex);
}

function focus(index: number) {
    focusIndex.value = index;
}
function select(index: number) {
    if (index > -1) {
        emits('select', {
            index,
            item: props.items![index],
        } as KeyboardNavigationItem);
    }
}

function mousedown(e: MouseEvent) {
    // We bind this to items so clicking on the item doesn't trigger a blur-event on inputs
    e.preventDefault();
}
</script>

<template>
    <div
        v-for="(item, index) in props.items"
        :key="item"
        tabindex="-1"
        @click="select(index)"
        @mouseover="focus(index)"
        @mousedown="mousedown"
    >
        <slot :item="item" :has-focus="index === focusIndex" />
    </div>
</template>
