<script setup lang="ts">
import { ref, watch } from 'vue';

import useGetCitiesByLatLong from '@core/queries/location/getCitiesByLatLong';
import useGetCitiesBySearchString from '@core/queries/location/getCitiesBySearchString';
import snippets from '@/utils/snippets';

import Alert from '@/components/Alert.vue';
import CitySmall from '@/components/items/CitySmall.vue';
import AutosuggestSearchField from '@/components/AutosuggestSearchField.vue';
import userLocation from '@/utils/user-location';
import { goTo } from '@/utils/url';
import { SEARCH } from '@/data/urls';
import Icon from '@/components/Icon.vue';

const emit = defineEmits(['update', 'select']);

const searchString = ref('');
const lat = ref(0);
const long = ref(0);
const hasUserLocationError = ref(false);

const { cities: citiesByLatLong } = useGetCitiesByLatLong(lat, long);
const { isPendingCitiesByString, cities: citiesByString } = useGetCitiesBySearchString(searchString);

watch(citiesByLatLong, (newCities) => {
    if (newCities!.length) {
        const value = `${newCities![0].zipcode}`;
        goTo(SEARCH, [{
            city: value,
        }]);
    }
});

function onSelect(item: any) {
    emit('select', item);
}

function onUpdate(value: string) {
    emit('update', value);
}

async function searchByUserLocation() {
    try {
        const { latitude, longitude } = await userLocation.get();
        lat.value = latitude;
        long.value = longitude;
    }
    catch (e) {
        hasUserLocationError.value = true;
    }
}

function onCloseUserLocationError() {
    hasUserLocationError.value = false;
}
</script>

<template>
    <Alert
        v-if="hasUserLocationError"
        :title="snippets.get('user_location_error_title')"
        :text="snippets.get('user_location_error_text')"
        @close="onCloseUserLocationError"
    />
    <div class="relative w-full flex items-center">
        <AutosuggestSearchField
            v-model="searchString"
            :is-pending="isPendingCitiesByString"
            :items="!isPendingCitiesByString ? citiesByString : []"
            :placeholder="snippets.get('search_by_location')"
            @update:model-value="onUpdate"
            @select="onSelect"
        >
            <template #item="{ item, hasFocus }">
                <CitySmall
                    :class="{ 'bg-light-grey': hasFocus }"
                    :city="item"
                />
            </template>
            <template #empty>
                <div class="px-6 py-4">
                    {{ snippets.get('no_location_found') }}
                </div>
            </template>
        </AutosuggestSearchField>
        <button class="c-user-location-link absolute right-8 vp9:z-[80]" type="button" @click="searchByUserLocation">
            {{ snippets.get('in_my_area') }}
        </button>
        <span class="absolute c-user-location-link-icon" @click="searchByUserLocation">
            <Icon name="marker" />
        </span>
    </div>
</template>
